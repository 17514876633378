<template>
    <div>
        <div class="modal fade custom-fields show" id="billing-address-edit-modal" aria-modal="true" style="display: block; padding-left: 0px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Billing Details")}}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="address_line_1">{{ $t("Street")}}*</label>
                                <input type="text" maxlength="255" v-model="form.street" id="address_line_1" class="form-control" :placeholder="$t('Enter Street')">
                                <div class="error-message" v-if="errors.street">{{ errors.street.join(' ') }}</div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="country">{{ $t("Country")}}*</label>

                                        <multiselect
                                                id="country"
                                                v-model="form.country"
                                                :options="countryOptions"
                                                :placeholder="$t('Select Country')"
                                                @open="findCountry"
                                                track-by="id"
                                                label="name"
                                                :class="{'disabled' : form.is_remote}"
                                        ></multiselect>
                                        <div class="error-message" v-if="errors.country">{{errors.country.join(' ')}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="state">{{ $t("State")}}*</label>
                                        <multiselect
                                                id="state"
                                                v-model="form.state"
                                                :options="stateOptions"
                                                :placeholder="$t('Select State')"
                                                @open="findState"
                                                track-by="id"
                                                label="name"
                                                :class="{'disabled' : form.is_remote}"
                                        ></multiselect>
                                        <div class="error-message" v-if="errors.state">{{errors.state.join(' ')}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="city">{{ $t("City")}}*</label>

                                        <multiselect
                                                id="city"
                                                v-model="form.city"
                                                :options="cityOptions"
                                                :placeholder="$t('Select City')"
                                                @open="findCity"
                                                track-by="id"
                                                label="name"
                                                :class="{'disabled' : form.is_remote}"
                                        ></multiselect>
                                        <div class="error-message" v-if="errors.city">{{ errors.city.join(' ')}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="postal_code">{{ $t("Postal Code")}}*</label>
                                        <input type="text" maxlength="50" v-model="form.postal_code" id="postal_code" class="form-control" :placeholder="$t('Enter Postal Code')">
                                        <div class="error-message" v-if="errors.postal_code">{{errors.postal_code.join(' ')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button class="button semi-button-info text-capitalize" @click.prevent="closeModal">{{ $t("back")}}</button>
                            <submit-button :loading="isLoading" :click="saveBillingData" type="info-button ml-auto">{{ $t("Save and Continue")}}</submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
    import {getCity, getCountry, getState} from "../../../app/api/CommonRequest";
    import Multiselect from 'vue-multiselect';
    import client from "../../../app/api/Client";

    export default {
        props: ['billing'],
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    id: '',
                    street: '',
                    country: '',
                    state: '',
                    city: '',
                    postal_code: ''
                },
                countryOptions: [],
                stateOptions: [],
                cityOptions: [],
                errors: [],
                isLoading: false
            }
        },
        methods: {
            saveBillingData() {
                try {
                    this.isLoading = true;
                    this.errors = [];
                    client().post(`/my-account/billing`, this.form).then(({data}) => {
                        if (data.status === 'SUCCESS') {
                            this.$toast.success(data.message);
                            this.closeModal();
                            this.$router.push({name: 'my-account.billing'});
                        } else {
                            this.$toast.error(data.message);
                        }
                        this.isLoading = false;
                    }).catch(err => {
                        if (err.response.status === 422) {
                            this.errors = err.response.data.message;
                        } else {
                            this.$toast.error(err.response.data.message);
                        }
                        this.isLoading = false;
                    });
                } catch (e) {
                }
            },

            closeModal() {
                this.$emit('closeModal');
            },


            findCountry() {
                getCountry().then((data) => {
                    this.countryOptions = data;
                });
            },

            findState() {

                if (!this.form.country) {
                    this.$toast.error(this.$t('Please select country first'));
                    return 0;
                }

                getState(this.form.country.id).then((data) => {
                    this.stateOptions = data;
                });
            },

            findCity() {
                if (!this.form.country || !this.form.state) {
                    this.$toast.error(this.$t('Please select country and state first'));
                    return 0;
                }

                getCity(this.form.country.id, this.form.state.id).then((data) => {
                    this.cityOptions = data;
                });
            },
            setDataToForm(data) {
                this.form.id = data.id;
                this.form.street = data.address_line_1;
                this.form.country = {
                    id: data.country.id,
                    name: data.country.name
                };
                this.form.state = {
                    id: data.state.id,
                    name: data.state.name
                };
                this.form.city = {
                    id: data.city.id,
                    name: data.city.name
                };
                this.form.postal_code = data.postal_code;
            }
        },
        created() {
            this.setDataToForm(this.billing);
        }
    }
</script>
